.preNav {
    background-color: rgb(29, 28, 28);
    display: grid;
    grid-template-columns: 5fr 2fr;
}

.preNav > div {
    padding: 0vmax 4vmax;
    display: flex;
    align-items: center;
}

.preNav > div > a {
    color: rgb(180, 180, 180);
    font-size: 0.9vmax;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-decoration: none;
    margin: 1vmax;
}

.preNav > div > span {
    color: rgba(180, 180, 180, 0.644);
    font-size: 0.8vmax;
}

.preNav > div > a:hover{
    color: white
}

.preNav > div svg{
    fill: rgb(180, 180, 180);
}

.preNav > div svg:hover {
    fill: white;
}