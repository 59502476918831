.HotAccessories {
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    width: 90vw;
    margin: auto;
    /* border: 1px solid red; */
}

.HotAccessories >div:first-child >img {
    /* border: 1px solid red; */
    width: 22vw;
    cursor: pointer;
    transition: all 0.5s;
}

.HotAccessories >div:first-child > img:hover {
    transform: translateY(-0.4vmax);
    box-shadow: 0px 6px 15px rgba(53, 53, 53, 0.363);
}

.HotAccessories > div:last-child {
    display: flex;
    flex-wrap: wrap;
    width: 75%;
}

