.nav {
    background-color: white;
    display: flex;
    padding: 1vmax;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(43,43,43, 0.2);
}

.logo {
    background-color: orangered;
    width: 58px;
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    right: 2vmax;
}

#logoImage {
    transition: 0.2s;
    transform: translateX(-54px);
}

#logoImage:hover{
    transform: translateX(0);
}

.navlinks {
    text-decoration: none;
    color: rgb(59,59,59);
    margin: 1vmax 1.2vmax;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.1vmax;
    font-weight: 500;
}

.navlinks:hover {
    color: orangered;
}

.searchBox > input{
    border: none;
    border-bottom:1px solid rgba(128,128,128, 0.76);
    width: 15vmax;
    font-size: 1.1vmax;
    color: rgb(61, 61, 61);
    outline: none;
    font-weight: 100;
}

.searchBox > svg{
    fill: rgb(61, 61, 61);
}