.ProductReviewCard {
    margin:0.5vmax;
    background-color: white;
    padding-bottom: 2vmax;
    cursor:pointer;
    transition: all 0.5s;
}

.ProductReviewCard:hover {
    transform: translateY(-0.4vmax);
    box-shadow: 0px 6px 15px rgba(53, 53, 53, 0.363);
}

.ProductReviewCard h5 {
    margin: 2vmax 0 0.5vmax 2vmax;
    color: rgb(94, 94, 94);
}

.ProductReviewCard span {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 1vmax;
    color: rgb(94, 94, 94);
    margin: 0 2vmax;
}

.ProductReviewCard b {
    font-size: 1vmax;
    color: orangered;
}

