.starProduct {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1vmax auto;
    width: 50%;
}

.starProduct img{
    transition: all 0.5s;
}

.starProduct img:hover{
    box-shadow: 6px 6px 15px rgba(53, 53, 53, 0.363);
}