.HotAccessoriesMenu {
    display: flex;
    justify-content: center;
}

.HotAccessoriesLink {
    margin: 1vmax;
    padding: 0.5vmax 0;
    text-decoration: none;
    color: rgb(94, 94, 94);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.HotAccessoriesLink:hover {
    color: orangered;
    border-bottom: 1px solid orangered;
}